<template>
  <v-dialog v-model="show" persistent fullscreen>
    <v-card>
      <v-card-title> Agregar adjudicación </v-card-title>
      <v-card-text max-height="80vh" class="overflow-y-auto">
        <v-row>
          <v-col
            class="FixedHeightContainer Content"
            cols="12"
            md="3"
            v-if="proveedor"
          >
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  label="filtrar lotes"
                  v-model="buscador"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-list>
                  <v-list-item-group
                    v-model="items"
                    multiple
                    active-class="border-active"
                    inactive-class="border-inactive"
                  >
                    <v-list-item
                      class="border-inactive"
                      v-for="(lote, index) in listadoLotes"
                      :key="index"
                      :value="lote.id"
                    >
                      {{ lote.nombre }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            :md="proveedor ? '9' : '12'"
            class="FixedHeightContainer Content"
          >
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete
                  label="Seleccione un proveedor"
                  outlined
                  clearable
                  :items="proveedores"
                  item-value="id"
                  item-text="nombre"
                  v-model="proveedor"
                />
              </v-col>
            </v-row>
            <v-row v-if="lotesData.length > 0" no-gutters>
              <v-col cols="12" md="12">
                <v-btn class="secondary mb-4" @click="asignarTodosLosItems()">
                  Asignar todos los items
                </v-btn>
                <v-btn
                  class="secondary mb-4 ml-4"
                  @click="asignarTodosLosItems(true)"
                >
                  Desasignar todos los items
                </v-btn>
              </v-col>
              <v-col cols="12" md="12">
                <v-data-table
                  hide-default-footer
                  fixed-header
                  height="660px"
                  :items="lotesData"
                  :headers="headers"
                  disable-pagination
                  :item-class="getRowClass"
                >
                  <template #[`item.disponible`]="{ item }">
                    {{ item.total_sub_proceso - item.cantidad_adjudicada }}
                  </template>
                  <template #[`item.cantidad_adjudicar`]="{ item }">
                    <v-text-field
                      class="pt-7 mb-0 pb-0"
                      v-model="item.cantidad_adjudicar"
                      outlined
                      :disabled="
                        +item.total_sub_proceso <= +item.cantidad_adjudicada
                      "
                      type="number"
                      @paste="(e) => e.preventDefault()"
                      :rules="calcularRulesCantidad(item)"
                    />
                  </template>
                  <template #[`item.monto`]="{ item }">
                    <v-text-field
                      class="pt-7 mb-0 pb-0"
                      v-model="item.monto_adjudicar"
                      outlined
                      :disabled="
                        +item.total_sub_proceso <= +item.cantidad_adjudicada
                      "
                      type="number"
                      @paste="(e) => e.preventDefault()"
                      prefix="$"
                      :rules="calcularRulesMonto(item)"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="12" md="5"></v-col>
          <v-col cols="12" md="6">
            <v-btn @click.stop="cerrar" outlined>Cerrar</v-btn>
            <v-btn
              @click.stop="show_modal = true"
              outlined
              class="secondary ml-4"
              :disabled="!proveedor || this.lotesData.length === 0"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmationDialog
      :show="show_modal"
      title="¿Deseas crear el registro?"
      message="El cambio es reversible, pero se estará registrando cada acción realizada"
      btnConfirmar="Continuar"
      @close="show_modal = false"
      @confirm="guardarAdjudicacion"
    />
  </v-dialog>
</template>
<script>
import { isNil } from "lodash";
import { helpers } from "vuelidate/lib/validators";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);
export default {
  name: "modalAdjudicacion",
  data: () => ({
    show_modal: false,
    items: [],
    lotesData: [],
    proveedores: [],
    lotes: [],
    headers: [
      {
        text: "Lote",
        align: "left",
        value: "nombre",
      },
      {
        text: "Agrupador",
        align: "left",
        value: "especificacion",
      },
      {
        text: "Cantidad solicitada",
        align: "center",
        value: "total_sub_proceso",
      },
      {
        text: "Cantidad disponible",
        align: "center",
        value: "disponible",
      },
      {
        text: "Cantidad a adjudicar",
        align: "center",
        value: "cantidad_adjudicar",
      },
      {
        text: "Monto",
        align: "center",
        value: "monto",
      },
    ],
    proveedor: null,
    buscador: "",
  }),
  methods: {
    async guardarAdjudicacion() {
      const sub_procesos_invalidos = this.lotesData.filter(
        ({
          cantidad_adjudicar,
          total_sub_proceso,
          monto_adjudicar,
          cantidad_adjudicada,
        }) =>
          +cantidad_adjudicada + +cantidad_adjudicar > total_sub_proceso ||
          isNil(cantidad_adjudicar) ||
          isNil(monto_adjudicar)
      );

      if (sub_procesos_invalidos.length > 0) {
        return this.pushAppMessage({
          message:
            "Tiene algunas asignaciones inválidas, por favor verifique que ninguna fila esté en rojo o que haya configurado las cantidades y montos en todas",
          type: "warning",
        });
      }
      const sub_procesos = this.lotesData
        .filter(
          ({
            cantidad_adjudicar,
            monto_adjudicar,
            cantidad_adjudicada,
            total_sub_proceso,
          }) =>
            cantidad_adjudicar > 0 &&
            monto_adjudicar > 0 &&
            +cantidad_adjudicada + +cantidad_adjudicar <= total_sub_proceso
        )
        .map(
          ({
            cantidad_adjudicar,
            id_sub_proceso,
            total_sub_proceso,
            monto_sub_proceso_obs,
            monto_adjudicar,
            cantidad_adjudicada,
          }) => ({
            id_sub_proceso_obs: id_sub_proceso,
            cantidad: cantidad_adjudicar,
            total_sub_proceso,
            monto_sub_proceso_obs,
            monto: monto_adjudicar,
            cantidad_adjudicada,
          })
        );

      if (sub_procesos.length === 0) {
        return this.pushAppMessage({
          message: "Debe configurar las cantidades y montos a adjudicar.",
          type: "warning",
        });
      }

      await this.services.AdjudicacionServices.adjudicar(
        this.id_proceso,
        this.proveedor,
        {
          sub_procesos,
        }
      );
      this.show_modal = false;
      this.proveedor = null;
      this.$emit("cerrar_recargar");
    },
    async obtenerProveedores() {
      const { data } = await this.services.AdjudicacionServices.listProveedores(
        this.id_proceso
      );
      this.proveedores = data;
    },
    async obtenerLotes() {
      const { data } = await this.services.AdjudicacionServices.listLotes(
        this.id_proceso,
        this.proveedor
      );
      this.lotes = data;
    },
    async obtenerInformacionLotes(idLoteProceso) {
      const { data } =
        await this.services.AdjudicacionServices.obtenerDetalleLote(
          idLoteProceso
        );
      this.lotesData.push(
        ...[data].flatMap(({ sub_procesos, ...ld }) =>
          sub_procesos.map(({ id, ggsp, ...lds }) => ({
            ...lds,
            ...ld,
            cantidad_adjudicada: ggsp.reduce(
              (total, item) => total + +item.cantidad,
              0
            ),
            id_sub_proceso: id,
          }))
        )
      );
    },
    getRowClass({
      cantidad_adjudicada,
      total_sub_proceso: cantidad,
      cantidad_adjudicar = 0,
      monto_adjudicar,
    }) {
      if (cantidad_adjudicada === +cantidad) {
        return "desactivadaRow";
      }
      if (
        +cantidad_adjudicar + +cantidad_adjudicada > +cantidad ||
        isNil(cantidad_adjudicar) ||
        isNil(monto_adjudicar)
      ) {
        return "invalidRow";
      }
      if (+cantidad_adjudicar === 0 && +cantidad_adjudicar < +cantidad) {
        return "parcialRow";
      }
      if (
        (+cantidad_adjudicada === 0 && cantidad_adjudicar === 0) ||
        +cantidad_adjudicar === +cantidad
      ) {
        return "disponibleRow";
      }
    },
    asignarTodosLosItems(desasignar = false) {
      this.lotesData = this.lotesData.map(
        ({ total_sub_proceso, cantidad_adjudicada, ...item }) => ({
          ...item,
          ...(+cantidad_adjudicada === 0 &&
            !desasignar && {
              cantidad_adjudicar: total_sub_proceso,
            }),
          ...(desasignar && {
            cantidad_adjudicar: null,
          }),
          cantidad_adjudicada,
          total_sub_proceso,
        })
      );
    },
    cerrar() {
      this.proveedor = null;
      this.items = [];
      this.lotesData = [];
      this.$emit("cerrar");
    },
    calcularRulesCantidad({ total_sub_proceso, cantidad_adjudicada }) {
      return [
        (v) => !!v || "Este campo es requerido",
        (v) => (v && v > 0) || "El valor debe ser mayor a 0",
        (v) =>
          (v && +v + +cantidad_adjudicada <= +total_sub_proceso) ||
          "La cantidad a adjudicar debe ser menor o igual al disponible",
      ];
    },
    calcularRulesMonto() {
      return [
        (v) => !!v || "Este campo es requerido",
        (v) => (v && v > 0) || "El valor debe ser mayor a 0",
        (v) => priceDecimals(v) || "El valor debe tener máximo dos decimales",
      ];
    },
  },
  components: { ConfirmationDialog },
  computed: {
    listadoLotes() {
      return this.buscador
        ? this.lotes.filter((lote) =>
            lote.nombre.toLowerCase().includes(this.buscador)
          )
        : this.lotes;
    },
    id_proceso() {
      return +this?.$route?.params?.idProceso || 0;
    },
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  watch: {
    async items(val) {
      if (val.length > this.lotesData.length) {
        const ultimo = val[val.length - 1];
        await this.obtenerInformacionLotes(ultimo);
      } else {
        this.lotesData = this.lotesData.filter((lote) => val.includes(lote.id));
      }
    },
    async proveedor(val) {
      this.lotes = [];
      this.items = [];
      this.lotesData = [];
      if (val) {
        await this.obtenerLotes();
      }
    },
  },
  async mounted() {
    await this.obtenerProveedores();
  },
};
</script>
<style>
.border-active {
  border: 2px dashed #ffffff !important;
  background: #3e4873 !important;
  color: aliceblue !important;
}

.border-inactive {
  border: 2px dashed #3e4873;
  background: #ffffff;
  color: #3e4873;
}

.FixedHeightContainer {
  height: 52rem;
  border: 2px black !important;
}

.Content {
  height: 52rem;
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px black !important;
}
.invalidRow {
  background-color: #e9b937 !important;
  color: white !important;
}
.disponibleRow {
  background-color: #ffffff !important;
  color: #2c1e7c !important;
}
.parcialRow {
  background-color: #63b8f1 !important;
  color: white !important;
}
.desactivadaRow {
  background-color: #f5550b !important;
  color: white !important;
}
.v-messages__message {
  color: white !important;
}
</style>
