<template>
  <v-dialog v-model="show" persistent>
    <v-card>
      <v-card-title class="mb-6"> Cambiar proveedor </v-card-title>
      <v-card-subtitle class="ml-6">
        {{ adjudicacion?.spo?.lotes_procesos?.nombre }}
      </v-card-subtitle>
      <v-card-text class="mt-4 pt-4">
        <v-row>
          <v-col cols="12" md="12">
            <v-autocomplete
              outlined
              :items="proveedores"
              item-text="nombre_comercial"
              item-value="id"
              label="Seleccione el proveedor"
              v-model="id_proveedor"
              clearable
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="3">
            <v-btn class="primary mr-4" @click.stop="$emit('cerrar')"
              >Cerrar</v-btn
            >
            <v-btn @click.stop="show_modal = true">Guardar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmationDialog
      :show="show_modal"
      title="¿Deseas cambiar el proveedor?"
      message="El cambio es reversible, pero se estará registrando cada acción realizada"
      btnConfirmar="Continuar"
      @close="show_modal = false"
      @confirm="cambiarProveedorAdjudicado"
    />
  </v-dialog>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "modalCambiarProveedor",
  components: { ConfirmationDialog },
  props: {
    proveedores: {
      type: Array,
      required: true,
    },
    adjudicacion: {
      type: Object,
      default: {},
    },
    show: {
      type: Boolean,
    },
    id_proceso_compra: {
      type: Number,
    },
  },
  data: () => ({
    show_modal: false,
    id_proveedor: null,
  }),
  methods: {
    async cambiarProveedorAdjudicado() {
      await this.services.AdjudicacionServices.cambiarProveedor(
        this.adjudicacion.id,
        this.id_proceso_compra,
        {
          id_proveedor: this.id_proveedor,
        }
      );
      this.show_modal = false;
      this.id_proveedor = null;
      this.$emit("cerrar_recargar");
    },
  },
};
</script>
