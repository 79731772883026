<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <ValidarEdicion
            :id_proceso="+id_proceso"
            :id_modificativa="5"
            @validar="validar"
          />
        </v-col>
      </v-row>
      <v-row v-if="validado">
        <v-col cols="12" md="8">
          <v-autocomplete
            :items="proveedores"
            outlined
            item-text="nombre"
            item-value="id"
            v-model="proveedor"
          />
        </v-col>
        <v-col class="pt-5" cols="12" md="4">
          <v-btn
            outlined
            @click="enviarDocumentoParticipacion"
            :disabled="!validado"
          >
            Enviar Comprobante
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ValidarEdicion from "./ValidarEdicion.vue";

export default {
  name: "envioComprobanteParticipacion",
  components: { ValidarEdicion, ConfirmationDialog },
  data: () => ({
    proveedores: [],
    validado: false,
    proveedor: null,
  }),
  computed: {
    id_proceso() {
      return this?.$route?.params?.idProceso || 0;
    },
  },
  methods: {
    async obtenerParticipantesProceso() {
      const { data } =
        await this.services.envioDocumentosProveedor.obtenerProveedores(
          this.id_proceso
        );
      this.proveedores = data;
    },
    async enviarDocumentoParticipacion() {
      await this.services.envioDocumentosProveedor.enviarNotificacion(
        this.id_proceso,
        this.proveedor
      );
      this.proveedor = null;
    },
    validar(valor) {
      this.validado = valor;
    },
  },
  async created() {
    await this.obtenerParticipantesProceso();
  },
};
</script>
