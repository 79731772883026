<template>
  <app-tab-component :items="tabs" v-if="tabs.length > 0">
    <template v-slot:[`contenido`]="{ item }">
      <component :is="item.component"></component>
    </template>
  </app-tab-component>
</template>
<script>
import envioComprobantesParticipacion from "./envioComprobantesParticipacion.vue";
export default {
  name: "envioDocumentosProveedor",
  data: () => ({}),
  computed: {
    tabs() {
      const tabs = [
        {
          nombre: "Envío de comprobante de participacion",
          component: envioComprobantesParticipacion,
        },
      ];
      return tabs;
    },
  },
};
</script>
