<template>
  <v-card>
    <v-card-actions>
      <v-row>
        <v-col>
          <ValidarEdicion
            :id_proceso="+this.id_proceso"
            :id_modificativa="2"
            @validar="validar"
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <v-row>
        <v-col>
          <v-data-table
            :items="listado"
            :headers="headers"
            :item-class="getRowClass"
            @pagination="paginar"
            :server-items-length="+totalItems"
          >
            <template v-slot:[`item.pujas`]="{ item }">
              {{
                item.participacion
                  .map((a) => a.OfertaSubastaAuditoria)
                  .flat()
                  .filter((b) => b.valida === true).length
              }}
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <app-menu-acciones
                :menu="getAcciones(item)"
                titulo="Acciones"
                :elemento="item"
              ></app-menu-acciones>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <ConfirmationDialog
      :show="show_modal"
      title="¿Deseas modificar el registro?"
      message="El cambio es reversible, pero se estará registrando cada acción realizada"
      btnConfirmar="Continuar"
      @close="(show_modal = false), (subastaSeleccionada = null)"
      @confirm="eliminar"
    />
    <ModalDetalleSubasta
      :is-open.sync="modalDetallesSubastaAbierto"
      :subasta="subastaSeleccionada"
    />
  </v-card>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import ValidarEdicion from "./ValidarEdicion.vue";
import ModalDetalleSubasta from "@/views/reporteriaDirector/subastaInversa/components/ModalDetalleSubasta.vue";
export default {
  name: "reiniciosSubasta",
  components: { ValidarEdicion, ConfirmationDialog, ModalDetalleSubasta },
  computed: {
    id_proceso() {
      return this?.$route?.params?.idProceso || 0;
    },
  },
  data: () => ({
    totalItems: 0,
    validado: false,
    listado: [],
    headers: [
      { text: "Sala", align: "center", sortable: false, value: "Sala.nombre" },
      {
        text: "Lote",
        align: "center",
        value: "LotesProceso.nombre",
      },
      {
        text: "Pujas auditadas",
        align: "center",
        sortable: false,
        value: "pujas",
      },
      { text: "Acciones", align: "center", sortable: false, value: "acciones" },
    ],
    show_modal: false,
    subastaSeleccionada: null,
    modalDetallesSubastaAbierto: false,
    options: {
      page: 1,
      per_page: 10,
    },
  }),
  methods: {
    async paginar({ page, itemsPerPage }) {
      this.options.page = page;
      this.options.per_page = itemsPerPage;
      await this.obtenerListadoSubastas();
    },
    validar(valor) {
      this.validado = valor;
    },
    async obtenerListadoSubastas() {
      const {
        data,
        headers: { total_rows },
      } = await this.services.reiniciosSuibastaServices.listSubastas(
        this.id_proceso,
        this.options
      );
      this.listado = data;
      this.totalItems = total_rows;
    },
    async eliminar() {
      await this.services.reiniciosSuibastaServices.eliminarSubasta(
        this.id_proceso,
        this.subastaSeleccionada.id
      );
      this.subastaSeleccionada = null;
      this.show_modal = false;
      await this.obtenerListadoSubastas();
    },
    getRowClass(item) {
      if (item.deleted_at) {
        return "highlight-row";
      }
      return "";
    },
    abrirModalDetallesSubasta(subasta) {
      this.subastaSeleccionada = { ...subasta };
      this.modalDetallesSubastaAbierto = true;
    },
    async exportarExcelUltimasOfertas(id) {
      const { data } = await this.services.Paac.getLastOfertaExcel(id);

      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const file = new File([blob], "asd", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = `subasta_historial_${id}.xlsx`;
      link.click();
    },
    getAcciones(item) {
      return [
        {
          nombre: item.deleted_at ? "Restaurar" : "Eliminar",
          icono: "mdi-delete",
          callback2: () => (
            (this.show_modal = true), (this.subastaSeleccionada = item)
          ),
          disabled: !this.validado,
        },
        {
          nombre: "Ver detalle",
          icono: "mdi-eye ",
          callback2: () => this.abrirModalDetallesSubasta(item),
          disabled: false,
          // disabled: !this.validado,
        },
        {
          nombre: "Descargar historial",
          icono: "mdi-eye ",
          callback2: () => this.exportarExcelUltimasOfertas(item.id),
          disabled: false,
          // disabled: !this.validado,
        },
      ];
    },
  },
  watch: {
    async validado(val) {
      if (val) {
        await this.obtenerListadoSubastas();
      }
    },
  },
  async created() {
    await this.obtenerListadoSubastas();
  },
};
</script>
<style>
.highlight-row {
  background-color: #ecb36d;
}
</style>
