<template>
  <v-card>
    <v-card-text v-if="tiene_permiso">
      <app-tab-component :items="tabs" v-if="tabs.length > 0">
        <template v-slot:[`contenido`]="{ item }">
          <component :is="item.component"></component>
        </template>
      </app-tab-component>
    </v-card-text>
  </v-card>
</template>
<script>
import envioDocumentosProveedor from './envioDocumentosProveedor.vue';
import ReiniciosSubasta from "./ReiniciosSubasta.vue";
import Adjudicacion from "./Adjudicacion.vue";
export default {
  name: "ModificacionesProcesocompra",
  computed: {
    tiene_permiso() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO");
    },
    tiene_permiso_modificacion_subasta() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_SUBASTA");
    },
    tiene_permiso_modificacion_adjudicacion() {
      return this.haveRole("ROLE_MODIFICAR_PROCESO_ADJUDICACION");
    },
    tabs() {
      const tabs = [
        {
          nombre: "Envío de documentos a proveedor",
          component: envioDocumentosProveedor,
        },
      ];
      if (
        this.forma_contratacion === 23 &&
        this.tiene_permiso_modificacion_subasta
      ) {
        tabs.push({
          nombre: "Reinicios de subasta",
          component: ReiniciosSubasta,
        });
      }
      if (this.forma_contratacion !== 23 && this.tiene_permiso_modificacion_adjudicacion) {
        tabs.push({
          nombre: "Administrar adjudicación",
          component: Adjudicacion,
        });
      }
      return tabs;
    },
  },
  props: {
    forma_contratacion: {
      type: Number,
      required: true,
    },
  },
};
</script>
